import auth from '@udacity/ureact-hoth';

import { getAnonymousIdCookie } from '../cookies/utils/get-anonymous-id-cookie';
import { publicEnv } from '../environment/public';

/**
 * Fetches the variant of an experiment for a user from Amplitude.
 */
export async function getExperimentVariantOnClient({
  flagKey,
  userProperties,
}: {
  flagKey: string;
  userProperties?: any;
}): Promise<string | null | undefined> {
  const anonymousId = getAnonymousIdCookie();
  const userId = auth.getCurrentUserId();
  const forcedVariation = getForcedVariation();

  if (forcedVariation) {
    return forcedVariation;
  }

  const params = new URLSearchParams({
    flag_key: flagKey,
  });

  if (anonymousId) params.append('device_id', anonymousId);
  if (userId) params.append('user_id', userId);
  if (userProperties) params.append('context', JSON.stringify(userProperties));

  try {
    const response = await fetch(`https://api.lab.amplitude.com/v1/vardata?${params}`, {
      headers: {
        Authorization: publicEnv.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY,
      },
    });

    if (!response.ok) {
      console.error(`Not OK returned from amplitude: ${response.status}`);
      return null;
    }

    const data: any = await response.json();

    return data?.[flagKey]?.key;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function getForcedVariation() {
  if (!window) return null;

  const { searchParams } = new URL(window.location.href);
  const forcedVariation = searchParams.get('forcedVariation');

  return forcedVariation;
}

/**
 * Removes the experiment root from the URL pathname.
 */
export function removeExperimentRootFromUrl(url: URL): URL {
  if (!url.pathname.startsWith('/test/')) {
    return url;
  }
  // Example: '/test/albus/variant_a'
  const parts = url.pathname.split('/').slice(4);
  url.pathname = `/${parts.join('/')}`;
  return url;
}
